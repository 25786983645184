import { Component } from "react";
import { connect } from "react-redux";

import { ActionTypes } from "./ActionTypes";
import { config } from "./config";

//Must be kept in sync with permissions.py
export const Permissions = {
  SEE_CITADELS: "see citadels",
  EDIT_CITADEL_META: "edit citadel meta",
  SEE_MOON_FRACKS: "see moon fracks",
  SEE_INACTIVE_FRACK_REPORT: "see inactive frack report",
  SEE_IHUBS: "see ihubs",
  SEE_STARGATES: "see stargates",
  SEE_TIMERS: "see timers",
  ADD_TIMER: "add timer",
  DELETE_TIMER: "delete timer",
  EDIT_ROLES: "edit roles",
  SET_TIMER_PRIORITY: "set timer priority",
  PROMOTE_SOV_TIMER: "promote sov timer",
  SEE_NOTIFICATIONS: "see notifications",
  SET_DESIRED_FUEL: "set desired fuel",
  SEE_UNANCHORING: "see unanchoring",
  SEE_UNANCHORING_KEEPSTARS: "see unanchoring keepstars",
  SEARCH_WAFFE: "search waffe",
  BOAT: "boat",
  SEE_USERS: "see users",
  SEE_TASKS: "see tasks",
  EDIT_TASK_TYPES: "edit task types",
  ADD_TOKENS: "add tokens",
  MANAGE_TOKENS: "manage tokens",
  SEE_QUARTERMASTER_REPORT: "see quartermaster report",
};

const SEARCH_ASSETS_TEMPLATE = "search ";

export function searchAssets(corpName) {
  return SEARCH_ASSETS_TEMPLATE + corpName;
}

export function hasSearchPermissions(permissions) {
  for (const permission of permissions) {
    if (permission.startsWith(SEARCH_ASSETS_TEMPLATE)) {
      return true;
    }
  }
  return false;
}

function setPermissions(permissions) {
  return {
    permissions: new Set(permissions),
    type: ActionTypes.SET_PERMISSIONS,
  };
}

function mapStateToProps(state) {
  let { permissions } = state;
  return { permissions };
}

export const PermissionRequired = connect(mapStateToProps)(
  class PermissionRequired extends Component {
    render() {
      let { role, children, anyOf, permissions } = this.props;
      if (anyOf === undefined) {
        anyOf = [role];
      }
      for (let perm of anyOf) {
        if (
          (perm instanceof Function && perm(permissions)) ||
          permissions.has(perm)
        ) {
          return children;
        }
      }
      return "";
    }
  }
);

function handleErrors(response) {
  if (response.status === 401) {
    throw Error("api_unauthorized");
  }
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export function loadPermissions(store) {
  return fetch(`${config.apiBase}/access/my_permissions`)
    .then(handleErrors)
    .then((response) => response.json())
    .then((data) => {
      store.dispatch(setPermissions(data));
      return true;
    })
    .catch((err) => {
      if (err.message === "api_unauthorized") {
        return false;
      }
    });
}
